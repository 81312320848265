<template>
    <div class="content">
        <base-header class="pb-6">
            <div class="row align-items-center py-2">
                <div class="col-lg-6 col-7"></div>
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <card>
                <div class="row mb-5">
                    <div class="col-md-8">
                        <h2 slot="header" class="mb-0">
                            Company Course and Course Folders
                        </h2>
                    </div>
                    <div class="col-md-4" v-if="locationId!==parentId">
                        <base-button class="btn base-button custom-btn btn-default float-right" @click="loadDataFromParentCompany">Load Data From Parent Company</base-button>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col-md-12">
                        <h4>Employee</h4>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Required Courses</label>
                        <br>
                        <el-select class="select-primary" v-model="requiredCoursesForEmployee" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="course in courses" :key="`manager-courses-${course.id}`" :label="course.name" :value="course.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Required Course Folders</label>
                        <br>
                        <el-select v-model="requiredCourseFoldersForEmployee" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="courseFolder in courseFolders" :key="`manager-course-folder-${courseFolder.id}`" :label="courseFolder.folder_name" :value="courseFolder.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Optional Courses</label>
                        <br>
                        <el-select class="select-primary" v-model="optionalCoursesForEmployee" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="course in courses" :key="`manager-courses-${course.id}`" :label="course.name" :value="course.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Optional Course Folders</label>
                        <br>
                        <el-select v-model="optionalCourseFoldersForEmployee" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="courseFolder in courseFolders" :key="`manager-course-folder-${courseFolder.id}`" :label="courseFolder.folder_name" :value="courseFolder.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12 mt-5">
                        <h4>Manager</h4>
                        <hr>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Required Courses</label>
                        <br>
                        <el-select class="select-primary" v-model="requiredCoursesForManager" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="course in courses" :key="`manager-courses-${course.id}`" :label="course.name" :value="course.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Required Course Folders</label>
                        <br>
                        <el-select v-model="requiredCourseFoldersForManager" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="courseFolder in courseFolders" :key="`manager-course-folder-${courseFolder.id}`" :label="courseFolder.folder_name" :value="courseFolder.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Optional Courses</label>
                        <br>
                        <el-select class="select-primary" v-model="optionalCoursesForManager" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="course in courses" :key="`manager-courses-${course.id}`" :label="course.name" :value="course.id"></el-option>
                        </el-select>
                    </div>
                    <div class="col-md-12">
                        <label class="form-control-label">Optional Course Folders</label>
                        <br>
                        <el-select v-model="optionalCourseFoldersForManager" placeholder="Courses" filterable multiple>
                            <el-option class="select-primary" v-for="courseFolder in courseFolders" :key="`manager-course-folder-${courseFolder.id}`" :label="courseFolder.folder_name" :value="courseFolder.id"></el-option>
                        </el-select>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12 text-right mt-2">
                        <base-button class="custom-btn" @click="saveCompanyCourses">Save</base-button>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import {Table, TableColumn, Select, Option} from "element-ui";
import Swal from "sweetalert2";

export default {
    name: 'details',
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn
    },
    data() {
        return {
            locationId: 0,
            parentId: 0,
            courses: [],
            courseFolders: [],
            requiredCoursesForEmployee: [],
            requiredCourseFoldersForEmployee: [],
            optionalCoursesForEmployee: [],
            optionalCourseFoldersForEmployee: [],
            requiredCoursesForManager: [],
            requiredCourseFoldersForManager: [],
            optionalCoursesForManager: [],
            optionalCourseFoldersForManager: [],
        }
    },
    created() {
        console.clear();
        this.locationId = this.$route.params.location_id;
        this.parentId = process.env.VUE_APP_CONCORD_HOSPITALITY_COMPANY_ID;
        this.getCourses();
        this.getCourseFolders();
        this.getChildLocationDetails(this.locationId);
    },
    methods: {
        getChildLocationDetails: function (locationId) {
            console.log(locationId);
            this.$http.get("location/fetch-child-location-details", {
                params: {
                    locationId: locationId,
                }
            }).then(response => {
                if (response.data.data.length > 0) {
                    console.clear();
                    response.data.data.forEach(row => {
                        console.log(row);
                        if (row.course_id != null && row.folder_id == null && row.is_employee == 1 && row.is_required == 1 && row.is_manager == 0) {
                            this.requiredCoursesForEmployee.push(row.course_id);
                        }
                        if (row.course_id == null && row.folder_id != null && row.is_employee == 1 && row.is_required == 1 && row.is_manager == 0) {
                            this.requiredCourseFoldersForEmployee.push(row.folder_id);
                        }
                        if (row.course_id != null && row.folder_id == null && row.is_employee == 1 && row.is_required == 0 && row.is_manager == 0) {
                            this.optionalCoursesForEmployee.push(row.course_id);
                        }
                        if (row.course_id == null && row.folder_id != null && row.is_employee == 1 && row.is_required == 0 && row.is_manager == 0) {
                            this.optionalCourseFoldersForEmployee.push(row.folder_id);
                        }

                        if (row.course_id != null && row.folder_id == null && row.is_employee == 0 && row.is_required == 1 && row.is_manager == 1) {
                            this.requiredCoursesForManager.push(row.course_id);
                        }
                        if (row.course_id == null && row.folder_id != null && row.is_employee == 0 && row.is_required == 1 && row.is_manager == 1) {
                            this.requiredCourseFoldersForManager.push(row.folder_id);
                        }
                        if (row.course_id != null && row.folder_id == null && row.is_employee == 0 && row.is_required == 0 && row.is_manager == 1) {
                            this.optionalCoursesForManager.push(row.course_id);
                        }
                        if (row.course_id == null && row.folder_id != null && row.is_employee == 0 && row.is_required == 0 && row.is_manager == 1) {
                            this.optionalCourseFoldersForManager.push(row.folder_id);
                        }
                    });
                }
            });
        },
        getCourses: function () {
            this.$http.post("course/all_courses", {course_status: "Active",}).then(resp => {
                this.courses = resp.data.courses;
            });
        },
        getCourseFolders: function () {
            this.$http.post("course/allcourse_folders", {folder_status: "Active"}, this.config).then(resp => {
                this.courseFolders = resp.data.folders;
            });
        },
        saveCompanyCourses: function () {
            let data = {
                locationId: this.locationId,
                requiredCoursesForEmployee: this.requiredCoursesForEmployee,
                requiredCourseFoldersForEmployee: this.requiredCourseFoldersForEmployee,
                optionalCoursesForEmployee: this.optionalCoursesForEmployee,
                optionalCourseFoldersForEmployee: this.optionalCourseFoldersForEmployee,
                requiredCoursesForManager: this.requiredCoursesForManager,
                requiredCourseFoldersForManager: this.requiredCourseFoldersForManager,
                optionalCoursesForManager: this.optionalCoursesForManager,
                optionalCourseFoldersForManager: this.optionalCourseFoldersForManager,
            };
            this.$http.post("location/update-signup-course-course-folders", data).then(response => {
                Swal.fire({
                    title: "Success!",
                    text: "Sign Up Page Updated.",
                    icon: "success",
                });
            });
        },
        loadDataFromParentCompany: function () {
            Swal.fire({
                title: "Info!",
                text: "Are you sure you want to load the data from the parent company?",
                icon: "info",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
            }).then(confirmation => {
                if (confirmation.isConfirmed == true) {
                    this.requiredCoursesForEmployee = [];
                    this.requiredCourseFoldersForEmployee = [];
                    this.optionalCoursesForEmployee = [];
                    this.optionalCourseFoldersForEmployee = [];
                    this.requiredCoursesForManager = [];
                    this.requiredCourseFoldersForManager = [];
                    this.optionalCoursesForManager = [];
                    this.optionalCourseFoldersForManager = [];
                    this.getChildLocationDetails(this.parentId);
                }
            });
        }
    }
}
</script>
<style>
.el-select {
    width: 100%;
}

/*.swal2-actions .swal2-cancel {
    display: block !important;
}*/
</style>
